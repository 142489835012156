import React, {PureComponent} from 'react';
import {
    Link,
  } from "react-router-dom";
// const webConfig = require('../webConfig.json');
// const newsApi = require('../newsApi.json');
// const newsFeed = newsApi.articles;
// let count = 0;
const moment = require('moment');
const webConfig = require('../config/webConfig.json');
const blogPosts = require('../config/blogPosts.json');
let postId;

class BlogPost1 extends PureComponent {

    componentDidMount () {

        webConfig.ads.forEach(ad => {

            let adJson = JSON.parse(ad.json);
            let script = document.createElement("script");
            script["src"] = adJson["src"];
            script["type"] = adJson["type"];
            script["data-cfasync"] = adJson["data-cfasync"];

            switch(ad["location"]) {
                case "ad-right":
                    document.getElementById("ad-right").appendChild(script);
                    break;
                case "ad-top":
                    document.getElementById("ad-top").appendChild(script);
                    break;
                default:
                    console.log("no match");
                    // code block
            }
            
        });

        // const script = document.createElement("script");
        // script["src"] = "//p366121.clksite.com/adServe/banners?tid=366121_740916_0";
        // script["type"] = 'text/javascript';
        // script["data-cfasync"] = false;
        // document.getElementById("ad-right").appendChild(script);

        

        // { '<>':'script', 'data-cfasync':'false', 'type':'text/javascript', 'src':'//p366121.clksite.com/adServe/banners?tid=366121_740916_0' },

            /***
             * // Save json obj on DB
             {
                 "src": "//p366121.clksite.com/adServe/banners?tid=366121_740916_0",
                 "type": "text/javascript",
                 "data-cfasync": false
                 "location": "ad-right"
             }
             * 
             * // New Table with assoc. Loop thorugh list here and append?
             *  

        const script = document.createElement("script");
        script["src"] = "//p366121.clksite.com/adServe/banners?tid=366121_740916_0";
        script["type"] = 'text/javascript';
        script["data-cfasync"] = false;
        document.getElementById("ad-right").appendChild(script);
            */


        // document.body.appendChild(script);
    }

    render() {
        postId = this.props.postId;

        // console.log(JSON.stringify(blogPosts));

        let blogPost = blogPosts.filter(obj => {
            // console.log("obj.hiveId: " + JSON.stringify(obj.hiveId));

            return obj.hiveId === postId
        })[0];

        // console.log("postId: "+JSON.stringify(postId));
        // console.log("BLOG POST: "+JSON.stringify(blogPost));

        // let rawContent = `It seems every year there gets a new movie like The Hangover Part II, which features a bunch of guys in a plane who have lots of sex. Not only that, the movie got so good that even Arnold Schwarzenegger was cast as the guy sitting at the other end of the room.
        // Well, here's some good news for cat people out there: If you want to give your cat a shot at becoming the king of big-screen sex, you'll probably need to hire a guy who knows how everything works.
        // From The Daily Mail:
        // A cat with a supermodel sex drive has become the highest paid celebrity on the porn industry.
        // In the latest stunt, the four-year-old black and white Kiko the cat became the "porn star" for the adult film industry, which has made a killing from cats after starring the likes of Angelina Jolie, Jennifer Aniston and Jennifer Lopez.
        // He will also be pictured with stars such as Madonna, Jennifer Lawrence and Kirsten Dunst.
        // Kiko, who was rescued by his owner David Haskins from a pet shop, says he loves being a star and "seems to be really enjoying his new celebrity status".
        // He will star in his own porn, called 'Kiko The Kitten'
        // ... and the film has been promoted at adult-oriented events by a local cat-themed TV station.
        // Haskins, of the Hollywood Hills town of Palomar, California, said: In the past he'd have been the star of it all but now it's really his job to get all the cat-related jokes out.
        // Kiko now has the sex appeal of a TV star and can even be seen on-screen with Jennifer Lopez
        // While you're at it, you could also get him some hot chocolate and a couple of cans of tuna to enjoy with his cat, if they're both really enjoying themselves.
        // The Hounds of Love campaign for Cat Love was started in 2005 by the Hounds of Love Association with the goal of making all animals more loveable.
        // It has since expanded to more than 30 UK events, giving local cats an opportunity for life beyond the cages. This year's show, in Birmingham, saw a whopping 3,600 cats and kittens on show.`;
        // let content = rawContent.split("\n");
        let content = blogPost.content.split("\n");
        let imageSource = "/static/images/" + blogPost.image;
        let postDate = moment(blogPost.postDate).format('MMMM Do YYYY, h:mm a');

        return (
        <div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
            <div className="container">
            <a className="navbar-brand" href="/">{webConfig["websiteName"]}</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <Link to="/" className="nav-link">Home</Link>
                    </li>
                    {/* <li className="nav-item">
                        <Link to="/posts" className="nav-link">Posts</Link>
                    </li> */}
                    <li className="nav-item">
                        <Link to="/about" className="nav-link">About</Link>
                    </li> 
                    </ul>
            </div>
            </div>
        </nav>

        <div className="container">

            <div className="row">

            <div className="col-lg-8">

                <h1 className="mt-4">{blogPost["title"]}</h1>

                <p className="lead">
                by&nbsp;
                <a href="#">{blogPost.author.name}</a>
                </p>

                <hr />

                <p>Posted on {postDate}</p>

                <hr />
                
                <img className="img-fluid rounded" src={imageSource} alt="" />

                <hr />

                {content.map((para, index) => {
                        return <p className="lead" key={index}>{para}</p>
                    })
                }
            </div>

            <div className="col-md-4" id="ad-right">

            {/* <script data-cfasync='false' type='text/javascript' src='//p366121.clksite.com/adServe/banners?tid=366121_740916_0'></script> */}

                {/* <div className="card my-4">
                <h5 className="card-header">Search</h5>
                <div className="card-body">
                    <div className="input-group">
                    <input type="text" className="form-control" placeholder="Search for..." />
                    <span className="input-group-btn">
                        <button className="btn btn-secondary" type="button">Go!</button>
                    </span>
                    </div>
                </div>
                </div>

                <div className="card my-4">
                <h5 className="card-header">Categories</h5>
                <div className="card-body">
                    <div className="row">
                    <div className="col-lg-6">
                        <ul className="list-unstyled mb-0">
                        <li>
                            <a href="#">Web Design</a>
                        </li>
                        <li>
                            <a href="#">HTML</a>
                        </li>
                        <li>
                            <a href="#">Freebies</a>
                        </li>
                        </ul>
                    </div>
                    <div className="col-lg-6">
                        <ul className="list-unstyled mb-0">
                        <li>
                            <a href="#">JavaScript</a>
                        </li>
                        <li>
                            <a href="#">CSS</a>
                        </li>
                        <li>
                            <a href="#">Tutorials</a>
                        </li>
                        </ul>
                    </div>
                    </div>
                </div>
                </div>

                <div className="card my-4">
                <h5 className="card-header">Side Widget</h5>
                <div className="card-body">
                    You can put anything you want inside of these side widgets. They are easy to use, and feature the new Bootstrap 4 card containers!
                </div>
                </div> */}

            </div> 

            </div>
        </div>
        <footer className="py-5 bg-dark">
            <div className="container">
            <p className="m-0 text-center text-white">Copyright &copy; {webConfig["websiteName"]} {(new Date()).getFullYear()}</p>
            </div>
        </footer>

        <script src="vendor/jquery/jquery.min.js"></script>
        <script src="vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
    </div>
        )

    }
}

export default BlogPost1;